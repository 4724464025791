import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slide = ({ imgUrl }) => {
  return (
    <div className="w-screen">
      <img
        src={imgUrl}
        alt="image_slider"
        className="inset-0 object-cover object-center h-full w-full"
      />
      

    </div>
  );
};

const FullSlider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(
        currentSlide === slides.length - 1 ? 0 : currentSlide + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide, slides]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  };

  return (
    <div className="">
      <Slider className="" {...settings}>
        {slides.map((slide, index) => (
          <Slide key={index} {...slide} />
        ))}
      </Slider>
  
 
</div>
  
  );
};

export default FullSlider;