import React from "react";
import LounasPageContainer from "../components/LounasPageContainer";
function Lounasmenu() {
return (

  <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8"> 
    <LounasPageContainer />
  </div>
)}

export default Lounasmenu;