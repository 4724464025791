import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  } from "firebase/firestore";


import { firestore } from "../firebase.config";


// Saving new Item menu
export const saveItem = async (data) => {
  await setDoc(doc(firestore, "foodItems", `${Date.now()}`), data, {
    merge: true,
  });
};
// Saving new Item lounas
export const saveLounasItem = async (data) => {
  await setDoc(doc(firestore, "lounasItems", `${Date.now()}`), data, {
    merge: true,
  });
};
export const saveNoteItem = async (data) => {
  await setDoc(doc(firestore, "notedb", `${Date.now()}`), data, {
    merge: true,
  });
};




// getall food items
export const getAllFoodItems = async () => {
  const items = await getDocs(
    query(collection(firestore, "foodItems"), orderBy("id", "desc"))
  );


  return items.docs.map((doc) => doc.data());
};
// getall lounasfood items
export const getAllLounasItems = async () => {
  const items = await getDocs(
    query(collection(firestore, "lounasItems"), orderBy("id", "desc"))
  );
  return items.docs.map((doc) => doc.data());
};
export const gethAllLounasItems = async () => {
  const items = await getDocs(
    query(collection(firestore, "lounashItems"), orderBy("id", "desc"))
  );
  return items.docs.map((doc) => doc.data());
};


  export const getAllNoteItems = async () => {
    const items = await getDocs(
      query(collection(firestore, "notedb"), orderBy("id", "desc"))
    );

  return items.docs.map((doc) => doc.data());
};



const foodCollectionRef = collection(firestore, "foodItems");
const noteCollectionRef = collection(firestore, "notedb");
const lounasCollectionRef = collection(firestore, "lounasItems");
const lounashCollectionRef = collection(firestore, "lounashItems");


class FoodDataService {


  addBooks = (newBook) => {
    return addDoc(foodCollectionRef, newBook);
  };
  
  addNotes = (newBook) => {
    return addDoc(noteCollectionRef, newBook);
  };

  addFoods = (newBook) => {
    return addDoc(lounasCollectionRef, newBook);
  };

  addhFoods = (newBook) => {
    return addDoc(lounashCollectionRef, newBook);
  };

  
  
  updateBook = (id, updatedBook) => {
    const bookDoc = doc(firestore, "foodItems", id);
    return updateDoc(bookDoc, updatedBook);
  };
  updateNote = (id, updatedBook) => {
    const bookDoc = doc(firestore, "notedb", id);
    return updateDoc(bookDoc, updatedBook);
  };
  updateFood = (id, updatedBook) => {
    const bookDoc = doc(firestore, "lounasItems", id);
    return updateDoc(bookDoc, updatedBook);
  };

  updatehFood = (id, updatedBook) => {
    const bookDoc = doc(firestore, "lounashItems", id);
    return updateDoc(bookDoc, updatedBook);
  };
  
  
  deleteBook = (id) => {
    const bookDoc = doc(firestore, "foodItems", id);
    return deleteDoc(bookDoc);
  };
  deleteNote = (id) => {
    const bookDoc = doc(firestore, "notedb", id);
    return deleteDoc(bookDoc);
  };
  deleteFood = (id) => {
    const bookDoc = doc(firestore, "lounasItems", id);
    return deleteDoc(bookDoc);
  };
  deletehFood = (id) => {
    const bookDoc = doc(firestore, "lounashItems", id);
    return deleteDoc(bookDoc);
  };


  
  
  getAllBooks = () => {
    return getDocs(foodCollectionRef);
  };
  getAllNotes = () => {
    return getDocs(noteCollectionRef);
  };
  getAllFoods = () => {
    return getDocs(lounasCollectionRef);
  };
  gethAllFoods = () => {
    return getDocs(lounashCollectionRef);
  };
  
  
  getBook = (id) => {
    const bookDoc = doc(firestore, "foodItems", id);
    return getDoc(bookDoc);
  };
  getNote = (id) => {
    const bookDoc = doc(firestore, "notedb", id);
    return getDoc(bookDoc);
  };
  getFood = (id) => {
    const bookDoc = doc(firestore, "lounasItems", id);
    return getDoc(bookDoc);
  };
  gethFood = (id) => {
    const bookDoc = doc(firestore, "lounashItems", id);
    return getDoc(bookDoc);
  };
}
export default new FoodDataService();

