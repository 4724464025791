
export const categories = [
  {
    id: 1,
    name: "PIZZA",
    urlParamName: "pizza",
  },
  {
    id: 2,
    name: "BURGERIT",
    urlParamName: "burgerit",
  },
  {
    id: 3,
    name: "SALAATIT",
    urlParamName: "salatit",
  },
  {
    id: 4,
    name: "HOTWINGS",
    urlParamName: "hotwings",
  },
  {
    id: 5,
    name: "KEBABIT",
    urlParamName: "kebabit",
  },
  {
    id: 6,
    name: "KANAKEBABIT",
    urlParamName: "kanakebabit",
  },

  {
    id: 7,
    name: "FALAFELIT",
    urlParamName: "falafelit",
  },
  {
    id: 8,
    name: "LAPSILLE",
    urlParamName: "lapsille",
  },
  {
    id: 9,
    name: "HOT GRILLI PIHVIT(NAUDAN ULKOFILEE)",
    urlParamName: "hotgrilli",
  },
  {
    id: 10,
    name: "KANAPIHVIT",
    urlParamName: "kanapihvit",
  },
  {
    id: 11,
    name: "LEIKKEET (PORSAAN ULKOFILEE)",
    urlParamName: "leikkeet",
  },
  {
    id: 12,
    name: "PASTAT",
    urlParamName: "pastat",
  },
];
export const days = 
[ 
  {
    id: 1,
    name: "MAANANTAI",
    urlParamName: "maanantai",
  },
  {
    id: 2,
   
   name:"TIISTAI",
    urlParamName: "tiistai",
  },
  {
    id: 3,
    name: "KESKIVIIKKO",
    urlParamName: "keskiviikko",
  },
  {
    id: 4,
    name: "TORSTAI",
    urlParamName: "torstai",
  },
  {
    id: 5,
    name: "PERJANTAI",
    urlParamName: "perjantai",
  },

  ];