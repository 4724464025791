import React, { useState, useEffect } from "react";
import { categories } from "../utils/data";
import FoodDataService from "../utils/firebaseFunctions";
import Loader from "./Loader";

import {
  MdCloudUpload,
  MdDelete,
} 
from "react-icons/md";

import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../firebase.config";


const AddBook = ({ id, setBookId }) => {
  const [title, setTitle] = useState("");
  const [calories, setCalories] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState(null);
  const [message, setMessage] = useState({ error: false, msg: "" });
  const [imageAsset, setImageAsset] = useState(null);
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadImage = (e) => {
    setIsLoading(true);
    const imageFile = e.target.files[0];
    const storageRef = ref(storage, `Images/${Date.now()}-${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const uploadProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        console.log(error);
        setFields(true);
        setMsg("Error while uploading : Try AGain 🙇");
        setAlertStatus("danger");
        setTimeout(() => {
          setFields(false);
          setIsLoading(false);
        }, 4000);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageAsset(downloadURL);
          setIsLoading(false);
          setFields(true);
          setMsg("Image uploaded successfully 😊");
          setAlertStatus("success");
          setTimeout(() => {
            setFields(false);
          }, 4000);
        });
      }
    );
  };

  const deleteImage = () => {
    setIsLoading(true);
    const deleteRef = ref(storage, imageAsset);
    deleteObject(deleteRef).then(() => {
      setImageAsset(null);
      setIsLoading(false);
      setFields(true);
      setMsg("Image deleted successfully 😊");
      setAlertStatus("success");
      setTimeout(() => {
        setFields(false);
      }, 4000);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (title === ""|| calories=== ""|| category=== ""|| price=== "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newBook = {
      id: `${Date.now()}`,
      imageURL: imageAsset,
      title,
      calories,
      category,
      price,
      
    };
    console.log(newBook);

    try {
      if (id !== undefined && id !== "") {
        await FoodDataService.updateBook(id, newBook);
        setBookId("");
        setMessage({ error: false, msg: "Updated successfully!" });
      } else {
        await FoodDataService.addBooks(newBook);
        setMessage({ error: false, msg: "New Book added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setTitle("");
    setCalories("");
    setCategory(null);
    setPrice("");
    setImageAsset(null);


  };
  



  const editHandler = async () => {
    setMessage("");
    try {
      const docSnap = await FoodDataService.getBook(id);
      console.log("the record is :", docSnap.data());
      setTitle(docSnap.data().title);
      setCalories(docSnap.data().calories);
      setCategory(docSnap.data().category);
      setPrice(docSnap.data().price);


      

    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  useEffect(() => {
    console.log("The id here is : ", id);
    if (id !== undefined && id !== "") {
      editHandler();
    }
  }, [id]);
  return (
 <>
      <div className="p-4 box">
        {message?.msg && (
          <div
          className={`p-4 rounded-md ${
            message.error ? "bg-red-500" : "bg-green-500"
          }`}
        >
          <button
            className="text-xl font-semibold leading-none right-0"
            onClick={() => setMessage("")}
          >
            ×
          </button>
          {message.msg}
          </div>
        )}

<form onSubmit={handleSubmit} className="space-y-4">
  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">A</span>
    <input
      type="text"
      placeholder="Title"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>


  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">B</span>
    <input
      type="text"
      placeholder="Ingredient"
      value={calories}
      onChange={(e) => setCalories(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>

  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">C</span>
    <input
      type="text"
      placeholder="Price"
      value={price}
      onChange={(e) => setPrice(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
  <div className="flex items-center space-x-2">
  <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">D</span>
  
          <select
            onChange={(e) => setCategory(e.target.value)}
            className="outline-none w-full text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
          >
            <option value="other" className="bg-white">
              Select Category
            </option>
            {categories &&
              categories.map((item) => (
                <option
                  key={item.id}
                  className="text-base border-0 outline-none capitalize bg-white text-headingColor"
                  value={item.urlParamName}
                >
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="group flex justify-center items-center flex-col border-2 border-dotted border-gray-300 w-full h-225 md:h-340 cursor-pointer rounded-lg">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!imageAsset ? (
                <>
                  <label className="w-full h-full flex flex-col items-center justify-center cursor-pointer">
                    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                      <MdCloudUpload className="text-gray-500 text-3xl hover:text-gray-700" />
                      <p className="text-gray-500 hover:text-gray-700">
                        Click here to upload image
                      </p>
                    </div>
                    <input
                      type="file"
                      name="uploadimage"
                      accept="image/*"
                      onChange={uploadImage}
                      className="w-0 h-0"
                    />
                  </label>
                </>
              ) : (
                <>
                  <div className="relative h-full">
                    <img
                      src={imageAsset}
                      alt="uploaded image"
                      className="w-full h-full object-cover"
                    />
                    <button
                      type="button"
                      className="absolute bottom-3 right-3 p-3 rounded-full bg-red-500 text-xl cursor-pointer outline-none hover:shadow-md  duration-500 transition-all ease-in-out"
                      onClick={deleteImage}
                    >
                      <MdDelete className="text-white" />
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
       
  <div className="flex justify-end">
    <button className="bg-blue-500 px-4 py-2 text-white rounded-md" type="submit">
      Add/Update
    </button>
  </div>
</form>



      </div>
    </>
  );
};

export default AddBook;
