import React, { useState } from "react";
import axios from "axios";

const Form = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validateForm = () => {
    if (!name || !email || !message) {
      setErrorMessage('Please fill in all fields');
      return false;
    }
    if (!email.includes('@') || !email.includes('.')) {
      setErrorMessage('Please enter a valid email address');
      return false;
    }
    return true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    if (validateForm()) {
      try {
        await axios.post('harun3851@hotmail.com', { name, email, message, to:'harun3851@hotmail.com' });
        alert('Message sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      } catch (error) {
        alert('Message failed to send.');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name:</label>
        <input type="text" id="name" className="border rounded w-full py-2 px-3" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email:</label>
        <input type="email" id="email" className="border rounded w-full py-2 px-3" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="mb-4">
        <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Message:</label>
        <textarea id="message" className="border rounded w-full py-2 px-3" rows="5" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      </div>
      <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">Send</button>
    </form>
  );
};

export default Form;
