import React, { useState, useEffect } from "react";
import { days } from "../utils/data";
import FoodDataService from "../utils/firebaseFunctions";
import montion from  "framer-motion";

import {
  MdFastfood,
  MdCloudUpload,
  MdDelete,
} 
from "react-icons/md";
import Loader from "./Loader";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../firebase.config";
import { getAllFoodItems, saveItem } from "../utils/firebaseFunctions";
import { actionType } from "../context/reducer";
import { useStateValue } from "../context/StateProvider";

const NoteAdd = ({ id, setBookId }) => {

  const [menu_1, setMenu_1] = useState("");
  const [menu_2, setMenu_2] = useState("");
  const [menu_3, setMenu_3] = useState("");
  const [menu_4, setMenu_4] = useState("");
  const [menu_5, setMenu_5] = useState("");
  const [day, setDay] = useState(null);

  const [message, setMessage] = useState({ error: false, msg: "" });
  const [imageAsset, setImageAsset] = useState(null);
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (day=== "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newBook = {
      id: `${Date.now()}`,
 day,
 menu_1,
 menu_2,
 menu_3,
 menu_4,
 menu_5,
    };
    console.log(newBook);

    try {
      if (id !== undefined && id !== "") {
        await FoodDataService.updateFood(id, newBook);
        setBookId("");
        setMessage({ error: false, msg: "Updated successfully!" });
      } else {
        await FoodDataService.addFoods(newBook);
        setMessage({ error: false, msg: "New Book added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setMenu_1("");
    setMenu_2("");
    setMenu_3("");
    setMenu_4("");
    setMenu_5("");
    setDay("Select Day");
  };
  const noteSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (day=== "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newBook = {
      id:`${Date.now()}`,
      day,
      menu_1,
      menu_2,
      menu_3,
      menu_4,   
      menu_5,   
     };
    console.log(newBook);

    try {
      if (id !== undefined && id !== "") {
        await FoodDataService.updateFood(id, newBook);
        setBookId("");
        setMessage({ error: false, msg: "Updated successfully!" });
      } else {
        await FoodDataService.addFoods(newBook);
        setMessage({ error: false, msg: "New Book added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }


    setMenu_1("");
    setMenu_2("");
    setMenu_3("");
    setMenu_4("");
    setMenu_5("");
    setDay("Select Day");
  };

  const editHandler = async () => {
    setMessage("");
    try {
      const docSnap = await FoodDataService.getFood(id);
      console.log("the record is :", docSnap.data());
      setDay(docSnap.data().day);
      setMenu_1(docSnap.data().menu_1);   
      setMenu_2(docSnap.data().menu_2);
      setMenu_3(docSnap.data().menu_3);
      setMenu_4(docSnap.data().menu_4);
      setMenu_5(docSnap.data().menu_5);

    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  useEffect(() => {
    console.log("The id here is : ", id);
    if (id !== undefined && id !== "") {
      editHandler();
    }
  }, [id]);
  return (
 <>
      <div className="p-4 box">
        {message?.msg && (
          <div
          className={`p-4 rounded-md ${
            message.error ? "bg-red-500" : "bg-green-500"
          }`}
        >
          <button
            className="text-xl font-semibold leading-none right-0"
            onClick={() => setMessage("")}
          >
            ×
          </button>
          {message.msg}
          </div>
        )}


<form onSubmit={handleSubmit} className="space-y-4">
 <div className="flex items-center space-x-2">
  <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">A</span>

        <select
            onChange={(e) => setDay(e.target.value)}
            className="outline-none w-full text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
          >
            <option value="other" className="bg-white">
              Select Day
            </option>
            {days &&
              days.map((item) => (
                <option
                  key={item.id}
                  className="text-base border-0 outline-none capitalize bg-white text-headingColor"
                  value={item.urlParamName}
                >
                  {item.name}
                </option>
              ))}
          </select> 
        </div>
     <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">B</span>
    <input
      type="text"
      placeholder="menu_1"
      value={menu_1}
      onChange={(e) => setMenu_1(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">C</span>
    <input
      type="text"
      placeholder="menu_2"
      value={menu_2}
      onChange={(e) => setMenu_2(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">D</span>
    <input
      type="text"
      placeholder="menu_3"
      value={menu_3}
      onChange={(e) => setMenu_3(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">E</span>
    <input
      type="text"
      placeholder="menu_4"
      value={menu_4}
      onChange={(e) => setMenu_4(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">E</span>
    <input
      type="text"
      placeholder="menu_5"
      value={menu_5}
      onChange={(e) => setMenu_5(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
 <div className="flex justify-end">
    <button className="bg-blue-500 px-4 py-2 text-white rounded-md" type="submit">
      2_Guncelle
    </button>
  </div>
</form>
  </div>
    </>
  );
};

export default NoteAdd;
