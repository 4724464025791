import React from "react";
import SendMail from "../components/SendMail";

const Yhteydenotto = () => {
  return (

    
    <div className="">
   <div className="flex flex-wrap justify-around mt-10  max-w-8xl bg-slate-500 ">
       <div className="flex flex-col text-center  px-8 pt-8 text-slate-100  ">
          <div className="flex rounded-b-larg  flex-col">
           <p className="text-lg font-semibold ">Yhteydenotto</p>
          </div>
          <div className="flex flex-col ">
           <p className="text-slate-100">+358 19 333311</p>
          </div>
          <div className="flex flex-col ">
           <p className="text-slate-100">Sähköposti: info@chefvelikebab.fi</p>
          </div>
       </div>
  
       <div className="flex flex-col justify-center  text-center px-8 pt-4  ">
          <div className="flex flex-col ">
          <p className="text-lg text-slate-100  font-semibold ">Osoite</p>
          </div>
          <div className="flex flex-col ">
          <p className="text-gray-100">Mäntynummentie 4, Lohja, Finland</p>
          <div className="flex justify-center ">
   
          </div>      
          </div>
        </div>
   
        <div className="flex flex-col justify-center px-8 py-2  text-center  ">
            <div className="flex flex-col  ">
            <p className="text-lg text-slate-100 font-semibold mt-4 ">Avoinna</p>
            </div>
            <div className="flex flex-col ">
            <p className="text-lg text-slate-100  ">Ma-To: 10.30-21.00</p>
            </div>
            <div className="flex flex-col ">
            <p className="text-lg text-slate-100 ">Pe-La: 10.30-22.00</p>
            </div>
            <div className="flex flex-col ">
            <p className="text-lg text-slate-100 ">Su: 11.00-21.00</p>
            </div>
         </div>
     </div>  
      <div className="flex flex-col md:p-30 lg:30 md:flex-row">
        <div className="w-full md:w-1/2 p-24">
          <SendMail />
        </div>
        <div className="w-full md:w-1/2 p-24">
          <iframe
            title="Map"
            width="100%"
            height="500"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=M%C3%A4ntynummentie%204,%20Lohja,%20Finland+(chef%20veli%20restaurat)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.maps.ie/distance-area-calculator.html">area maps</a>
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default Yhteydenotto;
