import React, { useEffect, useRef, useState } from "react";
import NotFound from "../img/NotFound.svg";



const RowContainer = ({ flag, data, scrollValue }) => {
  const rowContainer = useRef();

  const [items, setItems] = useState([]);

  return (
    <div
      ref={rowContainer}
      className={`w-full flex items-center gap-3  my-12 scroll-smooth  ${
        flag
          ? "overflow-x-scroll scrollbar-none"
          : "overflow-x-hidden flex-wrap justify-center"
      }`}
    >
      {data && data.length > 0 ? (
        data.map((item) => (
          <div
            key={item?.id}
            className=" bg-cardOverlay rounded-lg py-1 px-3  my-2 backdrop-blur-lg hover:drop-shadow-lg items-center"
          >

            <div className="w-full flex flex-col items-center">
              <p className=" text-red-00 text-lg md:text-6xl">
                {item?.week}
                </p>
                <div className="flex items-center gap-1">
                 <p className="text-lg  text-red-800  font-semibold">
                    ({item?.date})
                </p>
                </div>
                <div className="flex items-center gap-1">
                 <p className="text-lg text-headingColor font-semibold">
                   {item?.time}
                </p>
                 </div>
                <div className="flex items-center my-3 gap-1">
                  <p className="text-4xl text-headingColor font-semibold">
                  {item?.price}
                  </p>
                 </div>
             
       
   
          
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} className="h-340" />
          <p className="text-xl text-headingColor font-semibold my-2">
            Items Not Available
          </p>
        </div>
      )}
    </div>
  );
};

export default RowContainer;
