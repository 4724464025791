import React from "react";
import { useState } from "react";
import FoodAdd from "../components/FoodAdd";
import FoodList from "../components/FoodList";


const Menu = () => {
  const [bookId, setBookId] = useState("");
  const getBookIdHandler = (id) => {
    setBookId(id);
  };
return (
  <>
  <FoodAdd id={bookId} setBookId={setBookId} />
   <FoodList getBookId={getBookIdHandler} />
</>
)}

export default Menu




