import React from "react";
import K1 from '../img/1.jpg';
import K2 from '../img/2.jpg';
import K3 from '../img/3.jpg';
import K4 from '../img/4.jpg';
import K5 from '../img/5.jpg';
import K6 from '../img/6.jpg';
import K7 from '../img/7.jpg';
import K8 from '../img/8.jpg';
import K9 from '../img/9.jpg';
import K10 from '../img/10.jpg';
import K11 from '../img/11.jpg';
import K12 from '../img/12.jpg';

const photos = [
  K1,
  K2,
  K3,
  K4,
  K5,
  K6,
  K7,
  K8,
  K9,
  K10,
  K11,
  K12,
  


];

const PhotoGallery = () => {
  return (
    <div>
    <h1 className="text-6xl text-red-800 mt-24 text-center">KUVAGALLERIA</h1>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 m-4 mt-24">
    {photos.map((photo, index) => (
      <img key={index} src={photo} alt={`Photo ${index}`} className="w-full h-full object-cover border-4 border-red-500" />
    ))}
  </div>
  </div>
  
  );
};

export default PhotoGallery;
