export const actionType={
    SET_USER: 'SET_USER',
    SET_FOOD_ITEMS : "SET_FOOD_ITEMS",
    SET_LOUNAS_ITEMS : "SET_LOUNAS_ITEMS",
    SET_NOTE_ITEMS: "SET_NOTE_ITEMS",
};

const reducer =(state, action) => {
    console.log(action);
    switch(action.type){
        case actionType.SET_USER:
        return {
            ...state,
            user:action.user,
        };
        case actionType.SET_FOOD_ITEMS:
            return {
                ...state,
                foodItems:action.foodItems,
              
            };
            case actionType.SET_NOTE_ITEMS:
                return {
                    ...state,
                    noteItems:action.noteItems,
                  
                };
   
            case actionType.SET_LOUNAS_ITEMS:
                return {
                    ...state,
                 lounasItems:action.lounasItems,
                };
                case actionType.SET_LOUNASH_ITEMS:
                    return {
                        ...state,
                     lounashItems:action.lounashItems,
                    };
        default:
            
            return state;

        }
    };
export default reducer;