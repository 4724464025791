import React, { useState, useEffect } from "react";
import { days } from "../utils/data";
import FoodDataService from "../utils/firebaseFunctions";


const NoteAdd = ({ id, setBookhId }) => {

const [week, setWeek] = useState("");
const [date, setDate] = useState("");
const [time, setTime] = useState("");
const [price, setPrice] = useState("");

  const [message, setMessage] = useState({ error: false, msg: "" });
  const [imageAsset, setImageAsset] = useState(null);
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (week=== "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newBook = {
      id: `${Date.now()}`,
week,
date,
time,
price,
    };
    console.log(newBook);

    try {
      if (id !== undefined && id !== "") {
        await FoodDataService.updatehFood(id, newBook);
        setBookhId("");
        setMessage({ error: false, msg: "Updated successfully!" });
      } else {
        await FoodDataService.addhFoods(newBook);
        setMessage({ error: false, msg: "New Book added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setWeek("");
    setDate("");
    setTime("");
    setPrice("");
  
  };
  const noteSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (date=== "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newBook = {
      id:`${Date.now()}`,
      week,
      date,
      time,
      price,
     };
    console.log(newBook);

    try {
      if (id !== undefined && id !== "") {
        await FoodDataService.updatehFood(id, newBook);
        setBookhId("");
        setMessage({ error: false, msg: "Updated successfully!" });
      } else {
        await FoodDataService.addhFoods(newBook);
        setMessage({ error: false, msg: "New Book added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setWeek("");
    setDate("");
    setTime("");
    setPrice("");
  };

  const editHandler = async () => {
    setMessage("");
    try {
      const docSnap = await FoodDataService.gethFood(id);
      console.log("the record is :", docSnap.data());
      setDate(docSnap.data().date);
      setWeek(docSnap.data().week);   
      setTime(docSnap.data().time);
      setPrice(docSnap.data().price);
     

    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  useEffect(() => {
    console.log("The id here is : ", id);
    if (id !== undefined && id !== "") {
      editHandler();
    }
  }, [id]);

  return (
 <>
      <div className="p-4 box">
        {message?.msg && (
          <div
          className={`p-4 rounded-md ${
            message.error ? "bg-red-500" : "bg-green-500"
          }`}
        >
          <button
            className="text-xl font-semibold leading-none right-0"
            onClick={() => setMessage("")}
          >
            ×
          </button>
          {message.msg}
          </div>
        )}


<form onSubmit={handleSubmit} className="space-y-4">
<div className="flex space-x-5 ">
     <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">B</span>
    <input
      type="text"
      placeholder="week"
      value={week}
      onChange={(e) => setWeek(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">C</span>
    <input
      type="text"
      placeholder="date"
      value={date}
      onChange={(e) => setDate(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">D</span>
    <input
      type="text"
      placeholder="time"
      value={time}
      onChange={(e) => setTime(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
  <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">E</span>
    <input
      type="text"
      placeholder="price"
      value={price}
      onChange={(e) => setPrice(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
 <div className="flex justify-end">
    <button className="bg-blue-500 px-4 py-2 text-white rounded-md" type="submit">
      2_Guncelle
    </button>
    <div class="mb-2">
  
</div>
  </div>
  </div>
</form>
  </div>
    </>
  );
};

export default NoteAdd;
