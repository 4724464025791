import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDSxr9WqHl2peOrbJO9lBGKe3hYXa9MEqU",
    authDomain: "restaurantapp-2208d.firebaseapp.com",
    databaseURL: "https://restaurantapp-2208d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "restaurantapp-2208d",
    storageBucket: "restaurantapp-2208d.appspot.com",
    messagingSenderId: "745689785105",
    appId: "1:745689785105:web:31dc2382e622470ca422b0"
  };

 

  const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, firestore, storage };