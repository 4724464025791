import React from "react";
import MenuContainer  from "../components/MenuContainer";
function Ruokamenu() {
return (
 <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-6">   
<MenuContainer />
</div>
  )
};

export default Ruokamenu;