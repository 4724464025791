import React, { useEffect, useState } from "react";
import FoodDataService from "../utils/firebaseFunctions";


const NoteList = ({ getBookId }) => {
  const [books, setBooks] = useState([]);
  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = async () => {
    const data = await FoodDataService.getAllNotes();
    console.log(data.docs);
    setBooks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    
  };

  const deleteHandler = async (id) => {
    await FoodDataService.deleteNote(id);
    getBooks();
  };
  return (
    <>
  <div class="mb-2">
  <button class="bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray font-bold py-2 px-4 rounded edit" type="button" onClick={getBooks}>
    Refresh List
  </button>
</div>


      {/* <pre>{JSON.stringify(books, undefined, 2)}</pre>} */}
      <table class="table-auto w-full">
  <thead>
    <tr>
    <th class="px-4 py-2">#</th>
    <th class="px-4 py-2">Note </th>
      <th class="px-4 py-2">Category</th>

    </tr>
  </thead>
  <tbody>
    {books.map((doc,index) => {
      return (
        <tr key={doc.id}>
   <td class="border px-4 py-2">{index + 1}</td>
   <td className="border px-4 py-2">{doc.category}</td>
   <td className="border px-4 py-2">{doc.note}</td>
 


          <td class="border px-4 py-2">
            <button class="bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray font-bold py-2 px-4 rounded edit mr-2" type="button" onClick={(e) => getBookId(doc.id)}>
              Edit
            </button>
            <button class="bg-red-600 text-white hover:bg-red-500 focus:outline-none focus:shadow-outline-red font-bold py-2 px-4 rounded delete" type="button" onClick={(e) => deleteHandler(doc.id)}>
              Delete
            </button>
          </td>
        </tr>
      );
    })}
  </tbody>
</table>

    </>
  );
};

export default NoteList;
