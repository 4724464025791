import React, { useEffect, useState } from "react";
import FoodDataService from "../utils/firebaseFunctions";
import {montion} from  "framer-motion";


const BooksList = ({ getBookId }) => {
  const [books, setBooks] = useState([]);
  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = async () => {
    const data = await FoodDataService.getAllFoods();
    console.log(data.docs);
    setBooks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    
  };

  const deleteHandler = async (id) => {
    await FoodDataService.deleteFood(id);
    getBooks();
  };
  return (
    <>
  <div class="mr-3 text-right ">
  <button class="bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:shadow-outline-gray font-bold py-2 px-4 rounded edit" type="button" onClick={getBooks}>
    3_Sayfayı yenile
  </button>
</div>


      {/* <pre>{JSON.stringify(books, undefined, 2)}</pre>} */}
      <table class="table-auto w-full">
  <thead>
    <tr>
    <th class="px-4 py-2">#</th>
      <th class="px-4 py-2">day</th>
      <th class="px-4 py-2">menu 1</th>
      <th class="px-4 py-2">menu 2</th>
      <th class="px-4 py-2">menu 3</th>
      <th class="px-4 py-2">menu 4</th>
      <th class="px-4 py-2">menu 5</th>
      <th class="px-4 py-2">Action</th>
    </tr>
  </thead>
  <tbody>
    {books.map((doc, index) => {
      return (
        <tr key={doc.id}>
   <td class="border px-4 py-2">{index + 1}</td>
          <td className="border px-4 py-2">{doc.day}</td>
          <td class="border px-4 py-2">{doc.menu_1}</td>
          <td class="border px-4 py-2">{doc.menu_2}</td>
          <td class="border px-4 py-2">{doc.menu_3}</td>
          <td class="border px-4 py-2">{doc.menu_4}</td>
          <td class="border px-4 py-2">{doc.menu_5}</td>
 
          <td class="border px-4 py-2">
            <button class="bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray font-bold py-2 px-4 rounded edit mr-2" type="button" onClick={(e) => getBookId(doc.id)}>
              1_Degistir
            </button>
           {/* <button class="bg-red-600 text-white hover:bg-red-500 focus:outline-none focus:shadow-outline-red font-bold py-2 px-4 rounded delete" type="button" onClick={(e) => deleteHandler(doc.id)}>
              Delete
            </button>
           */}
          </td>
        </tr>
      );
    })}
  </tbody>
</table>

    </>
  );
};

export default BooksList;
