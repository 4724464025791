import React from "react";
import NoteAdd from "./NoteAdd";
import { useState } from "react";
import NoteList from "./NoteList";
import { useStateValue } from "../context/StateProvider";



const NoteMenu = () => {
  const [bookId, setBookId] = useState("");
  const [{  notedb }] = useStateValue();

  const getBookIdHandler = (id) => {
    console.log("The ID of document to be edited: ", id);
    setBookId(id);
  };
  return (

  <>
      
 <NoteAdd id={bookId} setBookId={setBookId} />
 <NoteList getBookId={getBookIdHandler} notes={notedb?.filter((n) => n.category === 'pizza')} />

  </>
  )
}

export default NoteMenu