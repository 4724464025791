import React, { useState, useEffect } from "react";
import { categories } from "../utils/data";
import FoodDataService from "../utils/firebaseFunctions";


const NoteAdd = ({ id, setBookId }) => {
  const [note, setNote] = useState("");
  const [category, setCategory] = useState(null);
  const [message, setMessage] = useState({ error: false, msg: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (category=== ""|| note=== "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newBook = {
      id: `${Date.now()}`,
    category,
      note,
    };
    console.log(newBook);

    try {
      if (id !== undefined && id !== "") {
        await FoodDataService.updateNote(id, newBook);
        setBookId("");
        setMessage({ error: false, msg: "Updated successfully!" });
      } else {
        await FoodDataService.addNotes(newBook);
        setMessage({ error: false, msg: "New Book added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setNote("");
    setCategory("Select Category");
  };
  const noteSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (category=== ""|| note=== "") {
      setMessage({ error: true, msg: "All fields are mandatory!" });
      return;
    }
    const newBook = {
      id:`${Date.now()}`,
      category,
      note,
    };
    console.log(newBook);

    try {
      if (id !== undefined && id !== "") {
        await FoodDataService.updateNote(id, newBook);
        setBookId("");
        setMessage({ error: false, msg: "Updated successfully!" });
      } else {
        await FoodDataService.addNotes(newBook);
        setMessage({ error: false, msg: "New Book added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }


    setNote("");
    setCategory("Select Category");
  };

  const editHandler = async () => {
    setMessage("");
    try {
      const docSnap = await FoodDataService.getNote(id);
      console.log("the record is :", docSnap.data());
      setNote(docSnap.data().note);
      setCategory(docSnap.data().category);   

    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  useEffect(() => {
    console.log("The id here is : ", id);
    if (id !== undefined && id !== "") {
      editHandler();
    }
  }, [id]);
  return (
 <>
      <div className="p-4 box">
        {message?.msg && (
          <div
          className={`p-4 rounded-md ${
            message.error ? "bg-red-500" : "bg-green-500"
          }`}
        >
          <button
            className="text-xl font-semibold leading-none right-0"
            onClick={() => setMessage("")}
          >
            ×
          </button>
          {message.msg}
          </div>
        )}


<form onSubmit={handleSubmit} className="space-y-4">
 <div className="flex items-center space-x-2">
  <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">E</span>
  
          <select
            onChange={(e) => setCategory(e.target.value)}
            className="outline-none w-full text-base border-b-2 border-gray-200 p-2 rounded-md cursor-pointer"
          >
            <option value="other" className="bg-white">
              Select Category
            </option>
            {categories &&
              categories.map((item) => (
                <option
                  key={item.id}
                  className="text-base border-0 outline-none capitalize bg-white text-headingColor"
                  value={item.urlParamName}
                >
                  {item.name}
                </option>
              ))}
          </select>
        </div>
     <div className="flex items-center space-x-2">
    <span className="inline-block bg-gray-200 px-2 py-1 rounded-md text-gray-700 text-sm font-medium">Note</span>
    <textarea
      rows={6} 
      placeholder="note"
      value={note}
      onChange={(e) => setNote(e.target.value)}
      className="border border-gray-300 px-2 py-1 rounded-md flex-1"
    />
  </div>
 <div className="flex justify-end">
    <button className="bg-blue-500 px-4 py-2 text-white rounded-md" type="submit">
      Add/Update
    </button>
  </div>
</form>
  </div>
    </>
  );
};

export default NoteAdd;
