import React, { useEffect, useState } from "react";
import FoodDataService from "../utils/firebaseFunctions";
import {montion} from  "framer-motion";


const BooksList = ({ getBookhId }) => {
  const [books, setBooks] = useState([]);
  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = async () => {
    const data = await FoodDataService.gethAllFoods();
    console.log(data.docs);
    setBooks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    
  };

  const deleteHandler = async (id) => {
    await FoodDataService.deletehFood(id);
    getBooks();
  };
  return (
    <>
  <div class="mr-44 text-right ">
  <button class="bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:shadow-outline-gray font-bold py-2 px-4 rounded edit" type="button" onClick={getBooks}>
   3_Sayfayı yenile
  </button>
</div>


      {/* <pre>{JSON.stringify(books, undefined, 2)}</pre>} */}
      <table class="table-auto w-full">
  <thead>
    <tr>
    <th class="px-4 py-2">#</th>
      <th class="px-4 py-2">week</th>
      <th class="px-4 py-2">date</th>
      <th class="px-4 py-2">time</th>
      <th class="px-4 py-2">price</th>
      <th class="px-4 py-2">Action</th>
    </tr>
  </thead>
  <tbody>
    {books.map((doc, index) => {
      return (
        <tr key={doc.id}>
   <td class="border px-4 py-2">{index + 1}</td>
          <td className="border px-4 py-2">{doc.week}</td>
          <td class="border px-4 py-2">{doc.date}</td>
          <td class="border px-4 py-2">{doc.time}</td>
          <td class="border px-4 py-2">{doc.price}</td>
          
 
          <td class="border px-4 py-2">
            <button class="bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray font-bold py-2 px-4 rounded edit mr-2" type="button" onClick={(e) => getBookhId(doc.id)}>
              1_Degistir
            </button>
          {/*<button class="bg-red-600 text-white hover:bg-red-500 focus:outline-none focus:shadow-outline-red font-bold py-2 px-4 rounded delete" type="button" onClick={(e) => deleteHandler(doc.id)}>
              Delete
            </button>*/}  
          </td>
        </tr>
      );
    })}
  </tbody>
</table>

    </>
  );
};

export default BooksList;
