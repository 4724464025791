import Nava from "./components/Nav";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Etusivu from "./pages/Etusivu";
import Ruokamenu from "./pages/Ruokamenu";
import Kuvagalleria from "./pages/Kuvagalleria";
import Yhteydenotto from "./pages/Yhteydenotto";
import Lounasmenu from "./pages/Lounasmenu";
import NoteMenu from "./components/NoteMenu";
import React, { useEffect } from "react";
import { useStateValue } from "./context/StateProvider";
import { getAllFoodItems } from "./utils/firebaseFunctions";
import { getAllLounasItems } from "./utils/firebaseFunctions";
import { gethAllLounasItems } from "./utils/firebaseFunctions";
import { getAllNoteItems } from "./utils/firebaseFunctions";
import { actionType } from "./context/reducer";
import Lounas from "./components/Lounas";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import { Navigate } from "react-router-dom";

function App() {
  const [{ user }] = useStateValue();
  const [{ foodItems }, dispatch] = useStateValue();
  const fetchData = async () => {
    await getAllFoodItems().then((data) => {
      dispatch({
        type: actionType.SET_FOOD_ITEMS,
        foodItems: data,
      });
    });
  };
  const fetchLounasData = async () => {
    await getAllLounasItems().then((data) => {
      dispatch({
        type: actionType.SET_LOUNAS_ITEMS,
        lounasItems: data,
      });
    });
  };
  const fetchNoteData = async () => {
    await getAllNoteItems().then((data) => {
      dispatch({
        type: actionType.SET_NOTE_ITEMS,
        noteItems: data,
      });
    });
  };
  const fetchLounashData = async () => {
    await gethAllLounasItems().then((data) => {
      dispatch({
        type: actionType.SET_LOUNASH_ITEMS,
        lounashItems: data,
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchLounasData();
  }, []);
  useEffect(() => {
    fetchLounashData();
  }, []);
  useEffect(() => {
    fetchNoteData();
  }, []);

  return (
    <div className="App">
      <Router>
       <Nava  />
        <Routes>
          <Route path="/" element={<Etusivu />}>
            <Route path="/etusivu"
              element={        
                         <Etusivu />
             
              }
            />
          </Route>
          <Route path="/ruokamenu" element={<Ruokamenu />}
          />
          <Route
            path="/lounasmenu"
            element={
              <>
           
                <Lounasmenu />
              </>
            }
          />
          <Route
            path="/kuvagalleria"
            element={
              <>
          
                <Kuvagalleria />
              </>
            }
          />
          <Route
            path="/Yhteydenotto"
            element={
              <>
         
                <Yhteydenotto />
              </>
            }
          />
          <Route
            path="/createItem"
            element={
              user ? (
                <>
              
                  <Menu />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/createNote"
            element={
              user ? (
                <>
                
                  <NoteMenu />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/createLounas"
            element={
              user ? (
                <>
                 
                  <Lounas />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/*"
            element={
              <>
              
                <h1>you are not in a page</h1>
              </>
            }
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}
export default App;