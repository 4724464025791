import React, {useRef, useState } from "react";
import NotFound from "../img/NotFound.svg";

const RowContainer = ({ flag, data}) => {
  const rowContainer = useRef();

  const [items, setItems] = useState([]);

  return (
    <div
      ref={rowContainer}
      className={`w-full flex items-center justify-center gap-3  my-12 scroll-smooth  ${
        flag
          ? "overflow-x-scroll scrollbar-none"
          : "overflow-x-hidden flex-wrap justify-center"
      }`}
    >
      {data && data.length > 0 ? (
        data.map((item) => (
          <div
            key={item?.id}
            className=" bg-cardOverlay  rounded-lg py-2 px-4  my-12 backdrop-blur-lg hover:drop-shadow-lg flex flex-col justify-center items-center relative"
          >

<div className="w-full flex flex-col -mt-8">
<p className="flex justify-center font-semibold uppercase  text-red-600 text-base  md:text-lg">
                {item?.day}
              </p>
            <p className="text-lg text-headingColor font-semibold">
            <span className="text-lg text-red-500">1-</span>  {item?.menu_1} 
            </p>
            <div className="flex items-center gap-8">
              <p className="text-lg text-headingColor font-semibold">
                <span className="text-lg text-red-500">2-</span> {item?.menu_2}
              </p>
            </div>
            <div className="flex items-center gap-8">
              <p className="text-lg text-headingColor font-semibold">
                <span className="text-lg text-red-500">3-</span> {item?.menu_3}
              </p>
            </div>
            <div className="flex items-center gap-8">
              <p className="text-lg text-headingColor font-semibold">
                <span className="text-lg text-red-500">4-</span> {item?.menu_4}
              </p>
            </div>
            <div className="flex items-center gap-8">
              <p className="text-lg text-headingColor font-semibold">
                <span className="text-lg text-red-500">5-</span> {item?.menu_5}
              </p>
            </div>
          </div>
          </div>
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} className="h-340" />
          <p className="text-xl text-headingColor font-semibold my-2">
            Items Not Available
          </p>
        </div>
      )}
    </div>
  );
};

export default RowContainer;
