import React, { useEffect, useState } from "react";
import { IoFastFood } from "react-icons/io5";
import { categories } from "../utils/data";
import { motion } from "framer-motion";
import RowContainer from "./RowContainer";
import { useStateValue } from "../context/StateProvider";
import NoteContainer from "./NoteContainer";



const MenuContainer = () => {
  const [filter, setFilter] = useState("pizza");


  const [{ foodItems,noteItems }, dispatch] = useStateValue();



  return (
    <section className="mx-auto mt-12 max-w-6xl px-2 sm:px-6 lg:px-8" id="menu">
    <div className="w-full flex flex-col items-center justify-center">
      <p className="text-2xl flex content-center ">
        RUOKA MENU
      </p>
  
      <div className="w-full flex flex-wrap items-center  justify-center gap-4 md:gap-8 py-4 md:py-6 lg:py-8 ">
        {categories &&
          categories.map((category) => (
            <motion.div
              whileTap={{ scale: 0.75 }}
              key={category.id}
              className={`group ${
                filter === category.urlParamName ? "bg-cartNumBg" : "bg-card"
              } w-20 md:w-24 min-w-[80px] md:min-w-[94px] h-24 md:h-28 cursor-pointer rounded-lg drop-shadow-xl flex flex-col gap-2 md:gap-3 items-center justify-center hover:bg-cartNumBg `}
              onClick={() => setFilter(category.urlParamName)}
            >
              <div
                className={`w-8 h-8 md:w-10 md:h-10 rounded-full shadow-lg ${
                  filter === category.urlParamName
                    ? "bg-white"
                    : "bg-cartNumBg"
                } group-hover:bg-white flex items-center justify-center`}
              >
                <IoFastFood
                  className={`${
                    filter === category.urlParamName
                      ? "text-textColor"
                      : "text-white"
                  } group-hover:text-textColor text-lg md:text-xl`}
                />
              </div>
              <p
                className={`text-sm ${
                  filter === category.urlParamName
                    ? "text-white"
                    : "text-textColor"
                } group-hover:text-white`}
              >
                {category.name}
              </p>
            </motion.div>
          ))}
      </div>
  
      <div className=" w-full items-center justify-center">
      <div className="">
      <NoteContainer
                data={noteItems?.filter((n) =>n.category === filter)}
/>
</div>
<div className="">
<RowContainer
data = {foodItems?.filter((n) => n.category === filter).sort((a, b) => a.title.localeCompare(b.title))}

/>

</div>        
      </div>
    </div>
  </section>
  
  );
};

export default MenuContainer;
