import React from "react";

const Footer = () => {
  return (
    <>
   <div className="flex flex-wrap justify-around mt-10  max-w-8xl bg-slate-600 ">
       <div className="flex flex-col text-center  px-8 pt-8 text-slate-100  ">
          <div className="flex rounded-b-larg  flex-col">
           <p className="text-lg font-semibold ">Yhteydenotto</p>
          </div>
          <div className="flex flex-col ">
           <p className="text-slate-100">+358 19 333311</p>
          </div>
          <div className="flex flex-col ">
           <p className="text-slate-100">Sähköposti: info@chefvelikebab.fi</p>
          </div>
       </div>
  
       <div className="flex flex-col justify-center  text-center px-8 pt-4  ">
          <div className="flex flex-col ">
          <p className="text-lg text-slate-100  font-semibold ">Osoite</p>
          </div>
          <div className="flex flex-col ">
          <p className="text-gray-100">Mäntynummentie 4, Lohja, Finland</p>
          <div className="flex justify-center ">
   
          </div>      
          </div>
        </div>
   
        <div className="flex flex-col justify-center px-8 py-2  text-center  ">
            <div className="flex flex-col  ">
            <p className="text-lg text-slate-100 font-semibold mt-4 ">Avoinna</p>
            </div>
            <div className="flex flex-col ">
            <p className="text-lg text-slate-100  ">Ma-To: 10.30-21.00</p>
            </div>
            <div className="flex flex-col ">
            <p className="text-lg text-slate-100 ">Pe-La: 10.30-22.00</p>
            </div>
            <div className="flex flex-col ">
            <p className="text-lg text-slate-100 ">Su: 11.00-21.00</p>
            </div>
         </div>
     </div>  
     <div className="mx-auto flex flex-row justify-around max-w-8xl bg-slate-800 ">
            <p className="text-lg text-slate-100 ">© 2022, Chef Veli Kebab. All rights reserved</p>
      </div>
    </>
  )
}

export default Footer




