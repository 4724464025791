import React, { useRef } from "react";
import NotFound from "../img/NotFound.svg";

const RowContainer = ({ flag, data }) => {
  const rowContainer = useRef();

  return (
    <div
      ref={rowContainer}
      className={`w-full flex items-center gap-3  scroll-smooth  ${
        flag
          ? "overflow-x-scroll scrollbar-none"
          : "overflow-x-hidden flex-wrap justify-center"
      }`}
    >
      {data && data.length > 0 ? (
        data.map((item) => (
          <div
            key={item?.id}
            className="w-full  bg-cardOverlay rounded-lg  backdrop-blur-lg hover:drop-shadow-lg flex flex-col items-center justify-evenly relative"
          >
            <div className="w-full flex flex-col items-start justify-end mt-4">
              <p className="text-textColor font-semibold text-base md:text-sm">
                {item?.note}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} className="h-340" alt="img" />
          <p className="text-xl text-headingColor font-semibold my-2">
            Items Not Available
          </p>
        </div>
      )}
    </div>
  );
};

export default RowContainer;
