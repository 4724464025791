import React, { useEffect, useState } from "react";
import FoodDataService from "../utils/firebaseFunctions";



const BooksList = ({ getBookId }) => {
  const [books, setBooks] = useState([]);
  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = async () => {
    const data = await FoodDataService.getAllBooks();
    console.log(data.docs);
    setBooks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    
  };

  const deleteHandler = async (id) => {
    await FoodDataService.deleteBook(id);
    getBooks();
  };
  return (
    <>
  <div class="mb-2">
  <button class="bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray font-bold py-2 px-4 rounded edit" type="button" onClick={getBooks}>
    Refresh List
  </button>
</div>


      {/* <pre>{JSON.stringify(books, undefined, 2)}</pre>} */}
      <table class="table-auto w-full">
  <thead>
    <tr>
    <th class="px-4 py-2">#</th>
      <th class="px-4 py-2">Title</th>
      <th class="px-4 py-2">Ingredient</th>
      <th class="px-4 py-2">category</th>
      <th class="px-4 py-2">price</th>
      <th class="px-4 py-2">image</th>
      <th class="px-4 py-2">Action</th>
    </tr>
  </thead>
  <tbody>
  {books
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((doc, index) => {
      return (
        <tr key={doc.id}>
          <td className="border px-4 py-2">{index + 1}</td>
          <td className="border px-4 py-2">{doc.title}</td>
          <td className="border px-4 py-2">{doc.calories}</td>
          <td className="border px-4 py-2">{doc.category}</td>
          <td className="border px-4 py-2">{doc.price}</td>
          <td className="border px-4 py-2">
            <div className="w-full flex items-center justify-between">
              <div className="w-40 h-40 -mt-8 drop-shadow-2xl">
                <img
                  src={doc?.imageURL}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          </td>
          <td className="border px-4 py-2">
            <button
              className="bg-gray-800 text-white hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray font-bold py-2 px-4 rounded edit mr-2"
              type="button"
              onClick={(e) => getBookId(doc.id)}
            >
              Edit
            </button>
            <button
              className="bg-red-600 text-white hover:bg-red-500 focus:outline-none focus:shadow-outline-red font-bold py-2 px-4 rounded delete"
              type="button"
              onClick={(e) => deleteHandler(doc.id)}
            >
              Delete
            </button>
          </td>
        </tr>
      );
    })}
</tbody>

</table>

    </>
  );
};

export default BooksList;
