import {fetchUser} from "../utils/fetchLocalStorageData";

const userInfo = fetchUser();


export const initialState = {
  user: userInfo,
  foodItems: null,
lounasItems:null,
lounashItems:null,
notedb:null,

};