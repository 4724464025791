import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import Logo from "../asssets/logo.png";
import { app } from "../firebase.config";
import React, { useState } from "react";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const navigation = [
  { name: "ETUSIVU", href: "/", current: true },
  { name: "MENU", href: "/Ruokamenu", current: false },
  { name: "LOUNAS", href: "/Lounasmenu", current: false },
  { name: "KUVAGALLERIA", href: "/Kuvagalleria", current: false },
  { name: "YHTEYDENOTTO", href: "/Yhteydenotto", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const firebaseAuth = getAuth(app);
  const provider = new GoogleAuthProvider();

  const [{ user, cartShow, cartItems }, dispatch] = useStateValue();
  const [isMenu, setIsMenu] = useState(false);

  const auth = getAuth();

  const login = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: "select_account" });

    if (!user) {
      const {
        user: { providerData },
      } = await signInWithPopup(auth, provider);
      const allowedEmail = "velirestaurant@gmail.com";
      if (providerData[0].email !== allowedEmail) {
        // Only allow the specified email address to log in
        return;
      }
      dispatch({
        type: actionType.SET_USER,
        user: providerData[0],
      });
      localStorage.setItem("user", JSON.stringify(providerData[0]));
    } else {
      setIsMenu(!isMenu);
    }
  };

  const logout = () => {
    setIsMenu(false);
    localStorage.clear();

    dispatch({
      type: actionType.SET_USER,
      user: null,
    });
  };

  return (
    <Disclosure
      as="nav"
      className="bg-red-50 sticky top-0  w-full rounded-b-lar px-5 drop-shadow-lg "
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-8xl sticky top-0 z-50   sm:px-6 md:pr-12 lg:pr-12">
            <div className="relative flex justify-around  h-24 items-center ">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center mt-4  rounded-md p-5 text-red-500 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-5 focus:ring-inset rounded-bl-lar drop-shadow-lg focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-8" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-8" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center justify-center  sm:justify-end">
                <div className="flex flex-shrink-0 items-center justify-center ">
                  <a
                    href="https://chefvelikebab.fi/"
                    classNames="curser-pointer"
                  >
                    <img
                      className="block mt-8 h-20 w-auto  lg:hidden"
                      src={Logo}
                      alt="Veli Restaurant"
                    />
                  </a>
                  <a
                    href="https://chefvelikebab.fi/"
                    classNames="curser-pointer"
                  >
                    <img
                      className="hidden h-36   lg:block"
                      src={Logo}
                      alt="Veli Restaurant"
                    />
                  </a>
                </div>

                <div className="hidden  md:ml-20 pt-4 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-red-500 text-white"
                            : "text-state-700 hover:bg-red-600 hover:text-white",
                          "rounded-md px-4 py-4  lg:text-xl  font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center  sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ">
                  <div className="relative">
                    <Menu.Button className="flex rounded-full bg-gray-800 mt-4 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8  w-8  rounded-full"
                        src={user ? user.photoURL : Logo}
                        onClick={login}
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-12 -mt-16 w-24 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-red ring-opacity-5 focus:outline-none">
                      {user && user.email === "velirestaurant@gmail.com" && (
                        <Link to={"/createItem"}>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-1 text-sm text-gray-700"
                                )}
                              >
                                Menu
                              </a>
                            )}
                          </Menu.Item>
                        </Link>
                      )}
                      {user && user.email === "velirestaurant@gmail.com" && (
                        <Link to={"/createNote"}>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-1 text-sm text-gray-700"
                                )}
                              >
                                Note
                              </a>
                            )}
                          </Menu.Item>
                        </Link>
                      )}
                      {user && user.email === "velirestaurant@gmail.com" && (
                        <Link to={"/createLounas"}>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-1 text-sm text-gray-700"
                                )}
                              >
                                Lounas
                              </a>
                            )}
                          </Menu.Item>
                        </Link>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={logout}
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-1 text-sm text-gray-700"
                            )}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="flex flex-col sm:flex-row px-3 sm:px-8 lg:px-12 py-4 text-center sm:text-center">
              <Disclosure.Button>
                {navigation &&
                  navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-red-600 text-white"
                          : "text-state-600 hover:bg-red-500 hover:text-white",
                        "block px-3 py-2 rounded-md font-bold sm:ml-4"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Link>
                  ))}
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}