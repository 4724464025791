import React from "react";
import LounasAdd from "./LounasAdd";
import LounasHeadAdd from "./LounasHeadAdd";
import { useState } from "react";
import LounasList from "./LounasList";
import LounasHeadList from "./LounasHeadList";
const Lounas = () => {

  const [bookId, setBookId] = useState("");

  const [bookhId, setBookhId] = useState("");


  const getBookIdHandler = (id) => {
    console.log("The ID of document to be edited: ", id);
    setBookId(id);}

    const getBookhIdHandler = (id) => {
      console.log("The ID of document to be edited: ", id);
      setBookhId(id);}
  

  return (
    <div className="mx-auto max-w-7xl  px-2 sm:px-6 lg:px-2 ">
      <div className="bg-slate-100">
        <p className="text-center mt-10 text-xl">Update Weeks</p>
      <div><LounasHeadList getBookhId={getBookhIdHandler} /></div>
      <div><LounasHeadAdd  id={bookhId} setBookhId={setBookhId}  /></div>
     
      </div>
      <div className="bg-red-100 mt-6">
      <p className="text-center mt-10 text-xl">Update Lounas</p>
      <LounasAdd id={bookId} setBookId={setBookId}  />
      <LounasList getBookId={getBookIdHandler} />
    </div>
    </div>


  )
}

export default Lounas;