import React from "react";
import FullSlider from "../components/FullSlider";
import Nav from "../components/Nav";
import Slider_1 from "../img/slider_1.jpg";
import Slider_2 from "../img/slider_2.jpg";
import Slider_3 from "../img/slider_3.jpg";
import P_1 from "../img/p_1.jpg";
import P_2 from "../img/p_2.jpg";
import P_3 from "../img/p_3.jpg";
import P_4 from "../img/p_4.jpg";
import P_5 from "../img/p_5.jpg";
import P_6 from "../img/p_6.jpg";
import Backt from "../img/backt.png";

import Bari from "../img/bari.png";
import Aramenu from "../img/aramenu.png";
const Etusivu = () => {
  const slides = [
    {
      imgUrl: Slider_1,
      title: "",
      desc: "",
    },
    {
      imgUrl: Slider_2,
      title: "",
      desc: "",
    },
    {
      imgUrl: Slider_3,
      title: "",
      desc: "",
    },
  ];

  return (
    <>
      <div className="w-screen object-fill">
        <div className="relative -mt-12 -z-10">
        <FullSlider slides={slides} />
        </div>
       
   
      </div>
    <div className="flex flex-wrap mt-6">
    <div className="relative w-full lg:w-1/2 p-5">
  <div
    className="absolute top-0 -left-24 w-full h-full"
    style={{
      backgroundImage: `url(${Backt})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top left',
      backgroundSize: '60%',
      opacity: 0.7,
      
    }}
  ></div>
  <div className="relative z-10 p-5">
    <h1 className="text-4xl md:text-4xl  font-bold text-black/100 text-center mb-5">
      Welcome To <span className="text-red-700/100">Chef Veli Restaurant</span>
    </h1>
    <p className="mt-0 md:text-xl text-sm text-center text-state-900">
      Ravintolamme tarjoaa huolella suunnitellun valikoiman maailman suosituimpia makuelämyksiä. Annoksemme ovat tuoreita, maukkaita ja tarjoillaan upeasti viimeisteltynä. Pyrimme myös tarjoamaan asiakkaillemme laadukasta ja ystävällistä palvelua. Ravintolamme rento ja kodikas ilmapiiri tarjoaa täydellisen ympäristön nauttia aterioistamme. Chef Velikebabina pidämme asiakkaidemme tyytyväisyyden aina etusijalla ja teemme parhaamme tarjotaksemme heille parasta mahdollista palvelua.
      <p>
        <span className="text-red-700/100  mt-0 md:text-3xl text-2xl">Tervetuloa Chef Velikebab-ravintolaan!</span>
      </p>
    </p>
  </div>
</div>



      <div
        class=" w-full h-full lg:w-1/2   lg:h-full p-6 "
    
      >
        <img
          class="object-cover border-8 border-red-700 rounded-3xl w-full h-full"
          src={Bari}
          alt="bari_image"
        />
      </div>
      </div>
      <div
        className="h-96 bg-fixed bg-center object-cover relative"
        style={{ backgroundImage: `url(${Aramenu})` }}
      >
        <div className="absolute inset-20 bg-white bg-opacity-50 backdrop-filter backdrop-brightness-125"></div>
        <div className="mt-1 p-32  text-center md:p-20 lg:p-32">
          <div className="relative">
            <h1 className=" text-center font-bold leading-tight text-lg md:text-4xl lg:text-5xl">
              "Hyvä ruoka on kuin taidetta, joka herkistää kaikki aistit ja tuo
              iloa ja nautintoa jokaiselle ruokailijalle."
            </h1>
          </div>
        </div>
      </div>
      
      <h2 className="text-center font-bold leading-tight text-lg md:text-4xl mt-16 ml-12 lg:text-5xl">
        "KUVAGALLERIA"
      </h2>
      <div className="container mx-auto">
        <p className="mt-4"></p>
      </div>
      <div className="grid grid-cols-1  mx-8 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <div className="relative">
          <a href={P_1} className="lightbox  ">
            <div class=" w-full rounded-lg  lg:h-full p-2 border-2 border-red-600 ">
              <img
                src={P_1}
                alt="Gallery Images"
                className="w-full rounded-lg h-full object-cover"
              />
            </div>
          </a>
        </div>
        <div className="relative ">
          <a href={P_2} className=" border-spacing-2 border-red-800">
            <div class=" w-full rounded-lg  lg:h-full p-2 border-2 border-red-600 ">
              <img
                src={P_2}
                alt="Gallery Images"
                className="w-full rounded-lg h-full object-cover"
              />
            </div>
          </a>
        </div>
        <div className="relative">
          <a href={P_3} className="lightbox">
            <div class=" w-full rounded-lg  lg:h-full p-2 border-2 border-red-600 ">
              <img
                src={P_3}
                alt="Gallery Images"
                className="w-full rounded-lg h-full object-cover"
              />
            </div>
          </a>
        </div>
        <div className="relative">
          <a href={P_4} className="lightbox">
            <div class=" w-full rounded-lg  lg:h-full p-2 border-2 border-red-600 ">
              <img
                src={P_4}
                alt="Gallery Images"
                className="w-full rounded-lg h-full object-cover"
              />
            </div>
          </a>
        </div>
        <div className="relative">
          <a href={P_5} className="lightbox">
            <div class=" w-full rounded-lg  lg:h-full p-2 border-2 border-red-600 ">
              <img
                src={P_5}
                alt="Gallery Images"
                className="w-full rounded-lg h-full object-cover"
              />
            </div>
          </a>
        </div>
        <div className="relative">
          <a href={P_6} className="lightbox">
            <div class=" w-full rounded-lg  lg:h-full p-2 border-2 border-red-600 ">
              <img
                src={P_6}
                alt="Gallery Images"
                className="w-full rounded-lg h-full object-cover"
              />
            </div>
          </a>
        </div>
      </div>
    </>
  );
};
export default Etusivu;