import React, {useState } from "react";
import { IoFastFood } from "react-icons/io5";
import { days } from "../utils/data";
import { motion } from "framer-motion";
import LounasContainer from "./LounasContainer";
import LounashContainer from "./LounashContainer";

import { useStateValue } from "../context/StateProvider";

const LounasPageContainer = ({ data }) => {
  const [filter, setFilter] = useState("maanantai");
  const [{ lounasItems, lounashItems}, dispatch] = useStateValue();
  const [showAll, setShowAll] = useState(false);

  return (
    <section className="w-full my-6" id="menu">
      <LounashContainer data={lounashItems} />
      <div className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex flex-wrap items-center justify-start lg:justify-center gap-4 md:gap-6 py-4 md:py-2 lg:py-2 ">
          {days &&
            days.map((day) => (
              <motion.div
                whileTap={{ scale: 0.75 }}
                key={day.id}
                className={`group ${
                  filter === day.urlParamName ? "bg-cartNumBg" : "bg-card"
                } w-20 md:w-24 min-w-[80px] md:min-w-[94px] h-24 md:h-28 cursor-pointer rounded-lg drop-shadow-xl flex flex-col gap-2 md:gap-3 items-center justify-center hover:bg-cartNumBg `}
                onClick={() => setFilter(day.urlParamName)}
              >
                <div
                  className={`w-8 h-8 md:w-10 md:h-10 rounded-full shadow-lg ${
                    filter === day.urlParamName
                      ? "bg-white"
                      : "bg-cartNumBg"
                  } group-hover:bg-white flex items-center justify-center`}
                >
                  <IoFastFood
                    className={`${
                      filter === day.urlParamName
                        ? "text-textColor"
                        : "text-white"
                    } group-hover:text-textColor text-lg md:text-xl`}
                  />
                </div>
                <p
                  className={`text-sm ${
                    filter === day.urlParamName
                      ? "text-white"
                      : "text-textColor"
                  } group-hover:text-white`}
                >
                  {day.name}
                </p>
              </motion.div>
            ))}
        </div>
        <button onClick={() => setShowAll(!showAll)}>
          {showAll ? "Piilota Kaikki" : "Näytä Kaikki"}
        </button>
        <div className="w-full">
          {showAll ? (
        <LounasContainer flag={false} data={lounasItems.reverse()} />
          ) : (
            <LounasContainer
              flag={false}
              data={lounasItems?.filter((n) => n.day === filter)}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default LounasPageContainer;
