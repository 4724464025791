import React, { useRef, useState } from "react";

import { motion } from "framer-motion";
import NotFound from "../img/NotFound.svg";

const RowContainer = ({ flag, data, scrollValue }) => {
  const rowContainer = useRef();


  return (
    <div
      ref={rowContainer}
      className={`w-full items-center  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  lg: md:pl-10  gap-6 scroll-smooth  ${
        flag
          ? "overflow-x-scroll scrollbar-none"
          : "overflow-x-hidden flex-wrap justify-left"
      }`}
    >
      {data && data.length > 0 ? (
        data.map((item) => (
          <div className="w-350 h-[175px] min-w-[350px] md:w-350 md:min-w-[350px]  bg-cardOverlay rounded-lg  px-2  my-12 hover:drop-shadow-lg"
            key={item?.id}
            >
           <div className="flex text-center justify-center pt-2 text-textColor whitespace-nowrap  font-semibold text-base md:text-lg items-center ">
              {item?.title}
              </div>
            <div className="flex">
              <div className="">
           
              <div>
            
                <div className="w-36  mt-1 overflow-hidden">
                  <p className="text-sm text-gray-500 whitespace-normal">
                    {item?.calories}
                  </p>
                </div>
              </div>
              <div className="flex items-center  gap-2">
                <p className="text-base text-red-500 font-semibold">
                  {item?.price}
                </p>
              </div>
              </div>
              <div className="w-full flex items-center justify-between">
                <motion.div className="w-36 h-36 my-2"  whileHover={{ scale: 0.75 }}>
                  <img
                    src={item?.imageURL}
                    alt=""
                    style={{ border: "none" }}
                    className="w-full h-full"
                  />
                </motion.div>
              </div>
            </div>
         
          </div>
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} className="h-340" />
          <p className="text-xl text-headingColor font-semibold my-2">
            Items Not Available
          </p>
        </div>
      )}
    </div>
  );
};

export default RowContainer;
